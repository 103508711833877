import {defineStore} from 'pinia'
import UserDetails from "~/composables/user-details";
import {NetworkService} from "~/utils/NetworkService";
import type {PasswordLoginRb} from "~/models/request/passwordLoginRb";
import type {User} from "~/models/user";
import {userStore} from "~/store/userStore";
import type {ForgetPasswordRb} from "~/models/request/forgetPasswordRb";
import {feeStore} from "~/store/feeStore";
import {clubStore} from "~/store/clubStore";
import {companyStore} from "~/store/companyStore";
import {gradeStore} from "~/store/gradeStore";
import {sessionStore} from "~/store/sessionStore";
import {UserLoginAccessDecision} from "~/utils/enums/UserLoginAccessDecision";
import {useGlobalEvent, useNotificationEvent} from "~/composables/useEventBus";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import {studentStore} from "~/store/studentStore";
import type {AddUpdateUserRb} from "~/models/request/addUpdateUserRb";
import type {SendBulkUserResendInvitesRb} from "~/models/request/sendBulkUserResendInvitesRb";

// @ts-ignore
export const authenticationStore = defineStore({
    id: 'authentication-store',
    persist: true,
    state: () => {
        return {
            authenticated: false,
            token: '',
            tenant: '',
            loggedInUser: {},
            failedRouteBeforeLogin: '', //Where user wanted to go before coming to login route
        }

    },
    actions: {
        _updateAuthenticatedStatus(value: boolean) {
            this.authenticated = value;
        },
        setLoggedInUser(user: UserDetails) {

            this._updateAuthenticatedStatus(true)
            this.loggedInUser = user;
        },
        clearLoggedInUser(user: UserDetails) {
            this.loggedInUser = user;
        },
        clearFailedRouteBeforeLogin: function () {
          this.failedRouteBeforeLogin = '';
        },
        doLogout() {
            this.loggedInUser = new UserDetails('', '', '', '', '', '', '');
            this.authenticated = false;
        },
        async sendOTP(recipient: string, companyName: string, type:string='email') {
            try{
                let url = '/api/otp/send';
                const ns = new NetworkService();
                let {successful} = await ns.post$fetch(url, {
                    "recipient": `${recipient}`,
                    "type": `${type}`,
                    "companyName": companyName,
                }, null, "sendOtp" + new Date().getMilliseconds() + "_", false)

                return successful == true;
            }catch (e){}
        },

        async verifyOTP(recipient: string, otp: string) {
            const config = useRuntimeConfig()
            let url = '/api/otp/verify';
            const ns = new NetworkService();
            let {result} = await ns.post$fetch(url, {
                "recipient": recipient,
                "otp": otp,
                "type": "email"
            }, null, "verifyOtp" + new Date().getMilliseconds() + "_", true)

            return result == true;
        },

        async verifyOTPWithUserDetails(recipient: string, otp: string, verifySignup: boolean = false, action: string, deviceDetails: any, type:string = 'email') {
            const config = useRuntimeConfig()
            let url = '/api/otp/verify-with-user-details';
            const ns = new NetworkService();
            let {result} = await ns.post$fetch(url, {
                "recipient": recipient,
                "otp": otp,
                "type": type,
                "verifySignup": verifySignup,
                "platform": config.public.NUXT_PUBLIC_SITE_URL,
                "action": action,
                "deviceDetails": deviceDetails
            }, null, "verifyOTPWithUserDetails" + new Date().getMilliseconds() + "_", true, true)

            return result;
        },

        async getUserDetailsByEmail(email: string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_HC_MESSAGING + '/v1/otp/user-details';
            const ns = new NetworkService();
            let {result} = await ns.post$fetch(url, {
                "email": email
            }, null, "getUserDetailsByEmail" + new Date().getMilliseconds() + "_", false, true)
            return result;
        },

        async getUserDetailsByEmailFromServer(email: string, saveLog: boolean = false, action: string, deviceDetails: any) {
            const config = useRuntimeConfig()
            let url = '/api/user/user-details-by-email';
            const ns = new NetworkService();
            let {result} = await ns.post$fetch(url, {
                "email": email,
                "saveLog": saveLog,
                "platform": config.public.NUXT_PUBLIC_SITE_URL,
                "action": action,
                "deviceDetails": deviceDetails
            }, null, "getUserDetailsByEmailFromServer" + new Date().getMilliseconds() + "_", false, true)
            return result;
        },

        async refreshToken(token: string) {
            const config = useRuntimeConfig()
            let url = '/api/token/refresh';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {
                "token": token
            }, null, "refreshToken" + new Date().getMilliseconds() + "_", true)

            return Result??{};
        },

        async loginWithPassword (passwordLoginRb:PasswordLoginRb) {
            const config = useRuntimeConfig()
            passwordLoginRb = { ...passwordLoginRb, platform: config.public.NUXT_PUBLIC_SITE_URL }
            let url = config.public.BASE_URL_SUB + '/SignUpService.svc/login';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, passwordLoginRb, null, "passwordLogin" + new Date().getMilliseconds() + "_")
            return Result;
        },

        async sendResetPasswordEmail (email:string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/forgotpassword';
            const ns = new NetworkService();
            const useCompanyStore = companyStore()
            const forgetPasswordRb:ForgetPasswordRb = {
                Email: email,
                WebUrl: config.public.siteUrl+'/'+(useCompanyStore.getCompanyCode??'')
            }; 
            let {success} = await ns.post$fetch(url, forgetPasswordRb, null, "sendResetPasswordEmail" + new Date().getMilliseconds() + "_")
            return success;
        },

        async sendBulkUserResendInvites (useremails: string[], companyId: number) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/sendBulkUserResendInvites';
            const ns = new NetworkService();
            const sendBulkUserResendInvitesRb:SendBulkUserResendInvitesRb = {
                User_emails: useremails,
                Company_id: companyId
            };
            let {success} = await ns.post$fetch(url, sendBulkUserResendInvitesRb, null, "sendBulkUserResendInvites" + new Date().getMilliseconds() + "_");
            return success;
        },

        async changePassword (newPassword:string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/api/user/updatePassword';
            const ns = new NetworkService();
            let {message} = await ns.post$fetch(url, {
                Password: newPassword,
            }, null, "changePassword" + new Date().getMilliseconds() + "_")
            return message=='success';
        },

        async validateShortId (shortId:string) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SUB + '/SignUpService.svc/api/short_token/check_and_delete';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {short_id: shortId}, null, "validateShortId" + new Date().getMilliseconds() + "_")
            return Result;
        },

        async populateAllNecessaryData(companyId:number, user:User){
            const useUserStore = userStore();
            const useFeeStore = feeStore();
            const useClubStore = clubStore();
            const useCompanyStore = companyStore();
            const useGradeStore = gradeStore();
            const useSessionStore = sessionStore();
            const useStudentStore = studentStore();
            await useClubStore.getAllClubsByCompanyId(companyId);
            await useCompanyStore.getCompanyDetails(companyId);
            await useGradeStore.getAllGradesByCompanyId(companyId);
            await useSessionStore.loadAllSessionByCompanyId(companyId);
            await useClubStore.getAllLocationsByCompanyId(companyId);
            await useClubStore.getClubCategoriesByCompanyId(companyId);
            await useFeeStore.loadAllFeesOfACompany(companyId);
            await useStudentStore.loadAndSetLoggedInStudent(user.Email);
            await useUserStore.loadUsersByCompanyId(companyId);
        },

        async processUserAccessWhoWantsToLogin(user:User){
            try{
                if(user?.id){
                    const useCompanyStore = companyStore();
                    const company = useCompanyStore.getCompanyDetailsLocal;
                    const foundUserCompany = user?.UserCompanies?.find((userCompany)=>userCompany.company_id==company.id);
                    const userBelongsToThisCompany = foundUserCompany?.id!=null;
                    if(userBelongsToThisCompany){
                        //Check if user is admin
                        const adminRoleFound = foundUserCompany.roleIds.find((roleId)=>roleId==1);
                        if(adminRoleFound){
                            return UserLoginAccessDecision.APPROVED;
                        }else{
                            return UserLoginAccessDecision.APPROVED;
                            // useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                            //     type: NotificationType.WARNING,
                            //     title: 'Coming Soon!',
                            //     message: 'We will roll out customer access to our platform soon. Stay tuned!',
                            // })
                            // return UserLoginAccessDecision.DENIED;
                        }
                    }
                    else{
                        if(company?.id>0){
                            useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                                type: NotificationType.WARNING,
                                title: 'Invalid user',
                                message: 'This user does not belong to this company',
                            })
                            return UserLoginAccessDecision.DENIED;
                        }
                        else{
                            const company = await useCompanyStore.getCompanyDetails(user.CompanyId);
                            if(company?.id<1){
                                useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                                    type: NotificationType.WARNING,
                                    title: 'Invalid user',
                                    message: 'No company found for this user',
                                })
                                return UserLoginAccessDecision.DENIED;
                            }else{
                                return UserLoginAccessDecision.CALL_LOGIN_FUNCTION_AGAIN;
                            }
                        }
                    }
                }else{
                    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
                        type: NotificationType.WARNING,
                        title: 'No user found',
                        message: 'No user found with this email.',
                    })
                    return UserLoginAccessDecision.DENIED;
                }
            }catch (e) {
                console.log(e);
            }
        },

        async isUserAssignedIntoThisCompany(user: User, companyId:number, assignIfNot:boolean = false){
            const foundUserCompanyMap = user.UserCompanies.find((item)=>item.company_id==companyId);
            if(foundUserCompanyMap){
                return user;
            }else if(assignIfNot){
                const useUserStore = userStore();
                const response  = await useUserStore.createUpdateUser(<AddUpdateUserRb>{...user, ...{company_id: companyId}}, true);
                if(response){
                    const result = await this.getUserDetailsByEmailFromServer(user.Email, false, '', '')
                    if(result){
                        return result;
                    }
                }
            }
        },

        setFailedRouteBeforeLogin: function (path:string) {
          this.failedRouteBeforeLogin = path;
        },

        setupEverythingForLogin: async function (user:User) {
            const useUserStore = userStore();
            const useCompanyStore = companyStore();
            useUserStore.setLoggedInUser(user);
            await this.populateAllNecessaryData(useCompanyStore.getCompanyId, user);
        },
    },
    getters: {
        isAuthenticated: state => state.authenticated,
        getFailedRouteBeforeLogin: state => state.failedRouteBeforeLogin,
        getLoggedInUser: function (state): UserDetails | null {
            // @ts-ignore
            return state.loggedInUser.email != '' && state.authenticated ? state.loggedInUser : null;
        }
    }

})