export default class UserDetails {
    private _name: string;
    private _email: string;
    private _userId: string;
    private _familyName: string;
    private _profilePicture: string;
    private _token: string;
    private _token_type: string;

    constructor(name: string, email: string, userId: string, familyName: string, profilePicture: string, token: string, token_type: string) {
        this._name = name;
        this._email = email;
        this._userId = userId;
        this._familyName = familyName;
        this._profilePicture = profilePicture;
        this._token = token;
        this._token_type = token_type;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }


    get token_type(): string {
        return this._token_type;
    }

    set token_type(value: string) {
        this._token_type = value;
    }

    get name(): string {
        return this._name;
    }

    get email(): string {
        return this._email;
    }

    get userId(): string {
        return this._userId;
    }

    get familyName(): string {
        return this._familyName;
    }

    get profilePicture(): string {
        return this._profilePicture;
    }
}